import React from "react";
import css from "@emotion/css";
import { Logo as LogoBig } from "../../components/Logo";

interface Props {
  collapsed: boolean;
}

export const Logo = React.memo(
  (props: Props): JSX.Element => (
    <LogoBig css={logoCss} href="/#/">
      {props.collapsed ? "S" : "SPIN"}
    </LogoBig>
  ),
);

const logoCss = css`
  font-size: 50px;
  margin-left: 25px;
  text-shadow: none;
  &:hover {
    text-shadow: none;
    text-decoration: none;
  }
`;
