import React from "react";
import { Card } from "antd";
import { centerCss, cardCss, fillLogoCss, rowCss } from "./PublicPage.styles";
import logo from "./logo.png";
import { Logo } from "../Logo";

export const PublicPage = (props: any) => (
  <div css={centerCss}>
    <Card css={cardCss}>
      <div css={rowCss}>
        <Logo>SPIN</Logo>
      </div>
      {props.children}
    </Card>
    <img css={fillLogoCss} src={logo} alt="logo" />
  </div>
);

export default PublicPage;
