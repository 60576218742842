import React from "react";
import css from "@emotion/css";
import { Icon } from "antd";

interface Props {
  collapsed: boolean;
  onClick: () => void;
}

export const MenuTrigger = React.memo((props: Props) => (
  <span css={containerCss}>
    <Icon
      type={props.collapsed ? "menu-unfold" : "menu-fold"}
      onClick={props.onClick}
    />
  </span>
));

const containerCss = css`
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #ab1702;
  }
  margin-right: 16px;
`;
