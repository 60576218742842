import React from "react";
import { Layout } from "antd";
import { HeaderButton } from "./header/HeaderButton";
import SideMenu from "./SideMenu";
import { Menu as MenuModel } from "../../data/models/Menu";
import { tree as menuTree } from "../../data/api/menus";
import { NotificationsButton } from "./header/notifications/NotificationsButton";
import { get } from "../../utils/cookies";
import { MenuTrigger } from "./header/MenuTrigger";
import { Breadcrumb } from "./header/Breadcrumb";
import { LogoutButton } from "./header/LogoutButton";
import { Header } from "./header/Header";
import { Content } from "./Content";
import { Logo } from "./Logo";
import { MainRouter, calculateRoutes } from "./Main.router";
const { Sider } = Layout;

interface State {
  collapsed: boolean;
  menu: MenuModel[];
}

class Main extends React.PureComponent<any, State> {
  public state: State = {
    collapsed: false,
    menu: [],
  };

  public toggle = () => {
    localStorage.setItem("main.menu.collapsed", (!this.state.collapsed).toString());
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  public componentWillMount() {
    if (get("jwt") === undefined) {
      window.location.hash = "/login";
    }
  }
  public componentDidMount() {
    this.setState({
      collapsed: localStorage.getItem("main.menu.collapsed") === "true",
    });
    if (get("jwt") !== undefined) {
      menuTree(undefined, true)
        .then(resp => {
          if (resp.Children) {
            calculateRoutes(resp.Children).then(() => {
              if (resp.Children) {
                this.setState({
                  menu: resp.Children,
                });
              }
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  public render() {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed} theme="dark">
          <Logo collapsed={this.state.collapsed} />
          <SideMenu
            data={this.state.menu}
            onSelect={this.onMenuSelect}
            selectedKeys={[this.props.location.pathname]}
            path={this.props.location.pathname}
            collapsed={this.state.collapsed}
          />
        </Sider>
        <Layout>
          <Header>
            <MenuTrigger collapsed={this.state.collapsed} onClick={this.toggle} />
            <Breadcrumb menu={this.state.menu} path={this.props.location.pathname} />
            <LogoutButton />
            <HeaderButton tooltip="Yardım" type="default" icon="question" to="" />
            <NotificationsButton />
            <HeaderButton tooltip="Mesajlar" type="default" icon="message" to="" />
          </Header>

          <Content>
            <MainRouter path={this.state.menu.length && this.props.location.pathname} />
          </Content>
        </Layout>
      </Layout>
    );
  }

  private onMenuSelect = (item: any) => {
    window.scrollTo(0, 0);
    this.props.history.push(item.key);
  };
}

export default Main;
