import React from "react";

import { Input, Icon, Form, Button, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { sha256 } from "js-sha256";

import { auth, logout } from "../../data/api/auth";
import { clearCache } from "../../data/api";
import PublicPage from "../../components/publicPage/PublicPage";

interface Props extends FormComponentProps {
  history: any;
  location: any;
}
interface State {
  loading: boolean;
  message?: string;
  error: boolean;
}

class Login extends React.Component<Props, State> {
  public state: State = { loading: false, error: false };

  public componentWillMount() {
    const state = this.props.location.state;
    if (state && state.logout) {
      logout().catch(e => console.error(e));
    }
  }
  public render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <PublicPage>
        <Form onSubmit={this.onSubmit}>
          <Form.Item>
            {getFieldDecorator("username", {
              rules: [
                {
                  required: true,
                  message: "Lütfen kullanıcı adını giriniz!",
                },
              ],
            })(<Input placeholder="Kullanıcı Adı" size="large" prefix={<Icon type="user" />} />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Lütfen şifrenizi giriniz!" }],
            })(<Input autoComplete="off" type="password" size="large" placeholder="Şifre" prefix={<Icon type="lock" />} />)}
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" size="large" loading={this.state.loading} style={{ width: "100%", letterSpacing: 1 }} type="primary" icon="login">
              Giriş
            </Button>
          </Form.Item>
          {this.state.message && <Alert message={this.state.message} type={this.state.error ? "error" : "success"} showIcon={!this.state.error} />}
        </Form>
      </PublicPage>
    );
  }

  private onSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.form.validateFields((errors: string, values: any) => {
      if (errors) {
        this.setState({
          loading: false,
          message: "Lütfen eksik bilgileri giriniz.",
          error: true,
        });
        return;
      }
      auth({ username: values.username, password: sha256(values.password) })
        .then(r => {
          clearCache();
          this.setState({ loading: false, error: false, message: "Giriş başarılı." }, () => setTimeout(() => this.props.history.replace("/"), 1000));
        })
        .catch((err: any) => {
          let message = "Beklenmeyen bir hata ile karşılaşıldı.";
          if (err) {
            switch (err.status) {
              case 401:
                message = "Hatalı Kullanıcı Adı veya Şifre girdiniz.";
                break;
              case 404:
                message = "Sunucuya ulaşılamıyor. Tekrar denedikten sonra giriş yapamazsanız sistem yöneticinize başvurunuz.";
                break;
              default:
                message = "Beklenmedik bir hata ile karşılaşıldı. Tekrar denedikten sonra giriş yapamazsanız sistem yöneticinize başvurunuz.";
            }
          }
          this.setState({
            loading: false,
            message,
            error: true,
          });
        });
    });
  };
}

export default Form.create()(Login);
