import { cache, load, IReq, QApi, qapi2Url } from "./index";
import { Menu } from "../models/Menu";

interface IChildReq extends IReq {
  childID: number;
}

export const treeAll = (): Promise<Menu[]> =>
  load(`menus/tree/all`, "GET", true, undefined);

export const tree = (req?: IReq, useCache: boolean = true): Promise<Menu> =>
  useCache
    ? cache(`menus/tree`, "GET", true, undefined)
    : load(`menus/tree`, "GET", true, undefined);

export const list = (req?: any, api?: QApi): Promise<Menu[]> =>
  load(`menus${qapi2Url(api)}`, "GET", true, undefined, api);
export const create = (data: Menu): Promise<Menu> =>
  load(`menus`, "POST", false, data);
export const read = (req: IReq): Promise<Menu> =>
  load(`menus/${req.ID}`, "GET", true, undefined);
export const update = (req: IReq, data: Menu): Promise<Menu> =>
  load(`menus/${req.ID}`, "PUT", true, data);
export const del = (req: IReq): Promise<Menu> =>
  load(`menus/${req.ID}`, "DELETE", true, undefined);

export const listChildren = (req: IChildReq): Promise<Menu[]> =>
  load(`menus/${req.ID}/children`, "GET", true, undefined);
export const createChildren = (req: IChildReq): Promise<Menu> =>
  load(`menus/${req.ID}/children/${req.childID}`, "POST", true, undefined);
export const readChildren = (req: IChildReq): Promise<Menu> =>
  load(`menus/${req.ID}/children/${req.childID}`, "GET", true, undefined);
export const delChildren = (req: IChildReq): Promise<Menu> =>
  load(`menus/${req.ID}/children/${req.childID}`, "DELETE", true, undefined);
