import React from "react";
import isEqual from "react-fast-compare";
import AsyncRoute from "../../components/AsyncRoute";

import { Switch } from "react-router-dom";
import { findInTree } from "../../utils/collections/tree";
import { Menu } from "../../data";

let FILTERED_ROUTES: any[];

export function calculateRoutes(menu: Menu[]) {
  FILTERED_ROUTES = routes()
    .filter((i: Route) => findInTree(menu, i.path, "Path", "Children"))
    .map((i: Route, idx: number) => (
      <AsyncRoute exact key={i.path} path={i.path} imp={i.imp} />
    ));
  return Promise.resolve(FILTERED_ROUTES);
}

export const MainRouter = React.memo(
  (props: any) => {
    return (
      <Switch>
        {FILTERED_ROUTES}
        {commonRoutes()}
      </Switch>
    );
  },
  (p: any, n: any) => isEqual(p, n),
);

interface Route {
  path: string;
  imp: any;
}

function routes() {
  const routeArr: Route[] = [
    { path: "/menus", imp: () => import("../menus/Menus") },
    { path: "/users", imp: () => import("../users/Users") },
    { path: "/roles", imp: () => import("../roles/Roles") },
    { path: "/system/api", imp: () => import("../system/Api") },
    { path: "/parameters", imp: () => import("../parameters/Parameters") },
    {
      path: "/definitions/companyTypes",
      imp: () => import("../definitions/companyTypes/CompanyTypes"),
    },
    {
      path: "/definitions/brands",
      imp: () => import("../definitions/brands/Brands"),
    },
    {
      path: "/definitions/customerTypes",
      imp: () => import("../definitions/customerTypes/CustomerTypes"),
    },
    {
      path: "/definitions/productGroups",
      imp: () => import("../definitions/productGroups/ProductGroups"),
    },
    {
      path: "/definitions/serviceFeatures",
      imp: () => import("../definitions/serviceFeatures/ServiceFeatures"),
    },
    {
      path: "/emails/emailTemplates",
      imp: () => import("../emails/emailTemplates/EmailTemplates"),
    },
    {
      path: "/emails/outgoing",
      imp: () => import("../emails/EmailOutgoing"),
    },
    {
      path: "/definitions/supplierTypes",
      imp: () => import("../definitions/supplierTypes/SupplierTypes"),
    },
    {
      path: "/definitions/departments",
      imp: () => import("../definitions/departments/Departments"),
    },
    {
      path: "/definitions/addresses/countries",
      imp: () => import("../definitions/addresses/countries/Countries"),
    },
    {
      path: "/definitions/addresses/provinces",
      imp: () => import("../definitions/addresses/provinces/Provinces"),
    },
    {
      path: "/definitions/addresses/districts",
      imp: () => import("../definitions/addresses/districts/Districts"),
    },
    {
      path: "/definitions/addresses/subdistricts",
      imp: () => import("../definitions/addresses/subDistricts/SubDistricts"),
    },
    {
      path: "/definitions/addresses/neighborhoods",
      imp: () => import("../definitions/addresses/neighborhoods/Neighborhoods"),
    },
    {
      path: "/spinCustomers",
      imp: () => import("../spinCustomers/SpinCustomers"),
    },
    { path: "/company/my", imp: () => import("../company/my/MyCompany") },
    {
      path: "/company/servicePoints",
      imp: () => import("../company/servicePoints/ServicePoints"),
    },
    {
      path: "/supply/suppliers",
      imp: () => import("../supply/suppliers/Suppliers"),
    },
    {
      path: "/supply/chains",
      imp: () => import("../supply/Chains"),
    },
    {
      path: "/storage/storages",
      imp: () => import("../storage/storages/Storages"),
    },
    {
      path: "/storage/transfers",
      imp: () => import("../storage/storageTransfers/StorageTransfers"),
    },
    {
      path: "/storage/StorageSearch",
      imp: () => import("../storage/StorageSearch"),
    },
    {
      path: "/product/products",
      imp: () => import("../product/Products"),
    },
    {
      path: "/product/pricings",
      imp: () => import("../pricings/Pricings"),
    },
    {
      path: "/product/stockLists",
      imp: () => import("../stockLists/StockLists"),
    },
    { path: "/product/stocks", imp: () => import("../stocks/Stocks") },
    { path: "/product/stockIns", imp: () => import("../stocks/StockIns") },

    { path: "/customers", imp: () => import("../customer/Customers") },
    { path: "/vehicles", imp: () => import("../vehicles/Vehicles") },
    { path: "/calendar", imp: () => import("../calendar/Calendar") },
    { path: "/task/tasks", imp: () => import("../tasks/Tasks") },
    { path: "/task/my", imp: () => import("../tasks/MyTasks") },
    { path: "/sale/offers", imp: () => import("../offers/Offers") },
    {
      path: "/sale/saleInfos",
      imp: () => import("../saleInfos/SaleInfos"),
    },
    {
      path: "/reports",
      imp: () => import("../reports/Reports"),
    },
    {
      path: "/purchase/purchases",
      imp: () => import("../purchases/Purchases"),
    },
    {
      path: "/purchase/purchaseApprovals",
      imp: () => import("../purchases/PurchaseApprovals"),
    },
    {
      path: "/purchase/purchaseOrders",
      imp: () => import("../purchases/PurchaseOrders"),
    },
    {
      path: "/purchase/purchaseWarehouses",
      imp: () => import("../purchases/PurchaseWarehouses"),
    },
    {
      path: "/purchase/supplyStatuses",
      imp: () => import("../purchases/SupplyStatuses"),
    },
    {
      path: "/workOrder/workOrders",
      imp: () => import("../workOrder/WorkOrders"),
    },
    {
      path: "/activityLogs",
      imp: () => import("../activityLog/ActivityLog"),
    },
  ];

  return routeArr;
}

function commonRoutes() {
  return [
    <AsyncRoute
      exact
      key="/"
      path="/"
      imp={() => import("../dashboard/Dashboard")}
    />,
    <AsyncRoute
      exact
      key="/dashboard"
      path="/dashboard"
      imp={() => import("../dashboard/Dashboard")}
    />,

    <AsyncRoute
      exact
      key="/forbidden"
      path="/forbidden"
      imp={() => import("../error/Forbidden")}
    />,
    <AsyncRoute key="notfound" imp={() => import("../error/NotFound")} />,
  ];
}
