import React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import { LocaleProvider } from "antd";
import tr_TR from "antd/lib/locale-provider/tr_TR";
import "moment/locale/tr";
import tr from "./locales/tr.json";
import "./index.css";
import { LanguageContext } from "./contexts/LanguageContext";

ReactDOM.render(
  <LocaleProvider locale={tr_TR}>
    <LanguageContext.Provider value={tr}>
      <App />
    </LanguageContext.Provider>
  </LocaleProvider>,
  document.getElementById("root") as HTMLElement,
);
