export function get(name: string): string | undefined {
  const cookie = document.cookie
    .split(";")
    .filter(item => item.indexOf(`${name}=`) >= 0);
  if (cookie && cookie.length > 0) {
    const val = cookie[0];
    return val.substring(val.indexOf("="))[1];
  }
  return undefined;
}
