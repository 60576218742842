import React from "react";
import css from "@emotion/css";

import { Input, Icon, Form, Button, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { sha256 } from "js-sha256";

import { setPassword } from "../../data/api/auth";
import PublicPage from "../../components/publicPage/PublicPage";
import { rowCss } from "../../components/publicPage/PublicPage.styles";

interface IProps extends FormComponentProps {
  age: number;
  name: string;
  history: any;
  location: any;
  match: any;
}
interface IState {
  loading: boolean;
}

class SetPassword extends React.Component<IProps, IState> {
  public state = { loading: false };

  public render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <PublicPage>
        <div css={rowCss}>
          <div css={descCss}>
            Yeni şifrenizi bu sayfa ile belirleyebilirsiniz. Girdiğiniz şifre
            ile şifre tekrarı aynı olmak zorundadır.
          </div>
        </div>
        <Form onSubmit={this.onSubmit}>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  message: "Yeni şifrenizi giriniz",
                },
              ],
            })(
              <Input
                type="password"
                placeholder="Şifre"
                size="large"
                prefix={<Icon type="lock" />}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("passwordAgain", {
              rules: [
                {
                  required: true,
                  message: "Şifre ile Şifre tekrarı aynı değil",
                  validator: (rule: any, value: any, callback: any) => {
                    const p1 = this.props.form.getFieldValue("password");
                    callback(p1 === value ? undefined : true);
                  },
                },
              ],
            })(
              <Input
                type="password"
                size="large"
                placeholder="Şifre (Tekrar)"
                prefix={<Icon type="lock" />}
              />,
            )}
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              size="large"
              loading={this.state.loading}
              style={{ width: "100%" }}
            >
              Kaydet
            </Button>
          </Form.Item>
        </Form>
      </PublicPage>
    );
  }
  // TODO: need to change validating and update
  private onSubmit = (e: any) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.form.validateFields((err: string, values: any) => {
      if (err) {
        this.setState({ loading: false });
        return;
      }
      setPassword(this.props.match.params.token, {
        password: sha256(values.password),
      })
        .then(r => {
          window.location.hash = "/login";
        })
        .catch((fail: any) => {
          if (fail.cause.status === 401) {
            message.error(
              "Bu sayfada olmamanız lazım, hatalı bir eposta ile yönlendiniz.",
              5,
            );
          }
          this.setState({ loading: false });
        });
    });
  };
}

export const descCss = css`
  text-align: left;
  margin-left: -14px;
`;

export default Form.create({})(SetPassword);
