import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { get } from "./utils/cookies";
import Main from "./pages/main/Main";
import Login from "./pages/login/Login";
import Password from "./pages/password/Password";

const App = (props: any) => {
  if (get("jwt") === undefined) {
    window.location.hash = "/login";
  }
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/login"
          render={(rProps: any) => <Login {...rProps} />}
        />
        <Route
          exact
          path="/setpassword/:token"
          render={(rProps: any) => <Password {...rProps} />}
        />
        <Route path="/" render={(rProps: any) => <Main {...rProps} />} />
      </Switch>
    </Router>
  );
};

export default App;
