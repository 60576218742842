// Export Project Icons

// Components
export { default as Printer } from "@ant-design/icons/lib/outline/PrinterOutline";
export { default as Edit } from "@ant-design/icons/lib/outline/EditOutline";
export { default as Filter } from "@ant-design/icons/lib/fill/FilterFill";
export { default as Save } from "@ant-design/icons/lib/outline/SaveOutline";
export { default as UserAdd } from "@ant-design/icons/lib/outline/UserAddOutline";
export { default as Exclamation } from "@ant-design/icons/lib/outline/ExclamationOutline";

// Main
export { default as Poweroff } from "@ant-design/icons/lib/outline/PoweroffOutline";
export { default as Question } from "@ant-design/icons/lib/outline/QuestionOutline";
export { default as Message } from "@ant-design/icons/lib/outline/MessageOutline";
export { default as Notification } from "@ant-design/icons/lib/outline/NotificationOutline";
export { default as MenuUnfold } from "@ant-design/icons/lib/outline/MenuUnfoldOutline";
export { default as MenuFold } from "@ant-design/icons/lib/outline/MenuFoldOutline";

// Menu
export { default as Team } from "@ant-design/icons/lib/outline/TeamOutline";
export { default as Solution } from "@ant-design/icons/lib/outline/SolutionOutline";
export { default as Project } from "@ant-design/icons/lib/outline/ProjectOutline";
export { default as Tool } from "@ant-design/icons/lib/outline/ToolOutline";
export { default as Mail } from "@ant-design/icons/lib/outline/MailOutline";
export { default as Heart } from "@ant-design/icons/lib/outline/HeartOutline";
export { default as Cluster } from "@ant-design/icons/lib/outline/ClusterOutline";
export { default as Inbox } from "@ant-design/icons/lib/outline/InboxOutline";
export { default as Gold } from "@ant-design/icons/lib/outline/GoldOutline";
export { default as FileDone } from "@ant-design/icons/lib/outline/FileDoneOutline";
export { default as Shopping } from "@ant-design/icons/lib/outline/ShoppingOutline";

export { default as Schedule } from "@ant-design/icons/lib/outline/ScheduleOutline";
export { default as OrderedList } from "@ant-design/icons/lib/outline/OrderedListOutline";

// Login
export { default as User } from "@ant-design/icons/lib/outline/UserOutline";
export { default as Lock } from "@ant-design/icons/lib/outline/LockOutline";
export { default as Login } from "@ant-design/icons/lib/outline/LoginOutline";

// Errors (NotFount & Forbidden)
export { default as Rollback } from "@ant-design/icons/lib/outline/RollbackOutline";
export { default as Home } from "@ant-design/icons/lib/outline/HomeOutline";
export { default as Disconnect } from "@ant-design/icons/lib/outline/DisconnectOutline";

// Export what antd other components need
export { default as CloseOutline } from "@ant-design/icons/lib/outline/CloseOutline";
export { default as CheckOutline } from "@ant-design/icons/lib/outline/CheckOutline";
export { default as LoadingOutline } from "@ant-design/icons/lib/outline/LoadingOutline";
export { default as CheckCircleOutline } from "@ant-design/icons/lib/outline/CheckCircleOutline";
export { default as InfoCircleOutline } from "@ant-design/icons/lib/outline/InfoCircleOutline";
export { default as CloseCircleOutline } from "@ant-design/icons/lib/outline/CloseCircleOutline";
export { default as ExclamationCircleOutline } from "@ant-design/icons/lib/outline/ExclamationCircleOutline";
export { default as CheckCircleFill } from "@ant-design/icons/lib/fill/CheckCircleFill";
export { default as InfoCircleFill } from "@ant-design/icons/lib/fill/InfoCircleFill";
export { default as CloseCircleFill } from "@ant-design/icons/lib/fill/CloseCircleFill";
export { default as ExclamationCircleFill } from "@ant-design/icons/lib/fill/ExclamationCircleFill";
export { default as UpOutline } from "@ant-design/icons/lib/outline/UpOutline";
export { default as DownOutline } from "@ant-design/icons/lib/outline/DownOutline";
export { default as LeftOutline } from "@ant-design/icons/lib/outline/LeftOutline";
export { default as RightOutline } from "@ant-design/icons/lib/outline/RightOutline";
export { default as RedoOutline } from "@ant-design/icons/lib/outline/RedoOutline";
export { default as CalendarOutline } from "@ant-design/icons/lib/outline/CalendarOutline";
export { default as SearchOutline } from "@ant-design/icons/lib/outline/SearchOutline";
export { default as BarsOutline } from "@ant-design/icons/lib/outline/BarsOutline";
export { default as StarFill } from "@ant-design/icons/lib/fill/StarFill";
export { default as FilterOutline } from "@ant-design/icons/lib/outline/FilterOutline";
export { default as CaretUpOutline } from "@ant-design/icons/lib/outline/CaretUpOutline";
export { default as CaretDownOutline } from "@ant-design/icons/lib/outline/CaretDownOutline";
export { default as CaretDownFill } from "@ant-design/icons/lib/fill/CaretDownFill";
export { default as PlusOutline } from "@ant-design/icons/lib/outline/PlusOutline";
export { default as FileOutline } from "@ant-design/icons/lib/outline/FileOutline";
export { default as FolderOpenOutline } from "@ant-design/icons/lib/outline/FolderOpenOutline";
export { default as FolderOutline } from "@ant-design/icons/lib/outline/FolderOutline";
export { default as PaperClipOutline } from "@ant-design/icons/lib/outline/PaperClipOutline";
export { default as PictureOutline } from "@ant-design/icons/lib/outline/PictureOutline";
export { default as EyeOutline } from "@ant-design/icons/lib/outline/EyeOutline";
export { default as DeleteOutline } from "@ant-design/icons/lib/outline/DeleteOutline";
export { default as DownloadOutline } from "@ant-design/icons/lib/outline/DownloadOutline";
export { default as UndoOutline } from "@ant-design/icons/lib/outline/UndoOutline";
