import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { asyncComponent } from "react-async-component";
import { Spin } from "antd";

export interface Props extends RouteProps {
  imp: () => Promise<any>;
}

const AsyncRoute = (props: Props) => {
  const { imp, ...routeProps } = props;
  const Component = asyncComponent({
    resolve: imp,
    LoadingComponent: () => (
      <div style={{ textAlign: "center", paddingTop: 50 }}>
        <Spin delay={400} size="large" tip="Sayfanız yükleniyor" />
      </div>
    ),
  });

  return (
    <Route
      {...routeProps}
      render={(rProps: any) => <Component {...rProps} />}
    />
  );
};

export default AsyncRoute;
