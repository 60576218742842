import { load } from "./index";

export interface IAuthReq {
  username: string;
  password: string;
}

export const auth = (data: IAuthReq): Promise<any> =>
  load("auth", "POST", false, data);

export const logout = (): Promise<any> =>
  load("auth/logout", "GET", false, undefined);

export const setPassword = (token: string, data: any): Promise<any> =>
  load(`auth/setpassword/${token}`, "POST", false, data);

export default auth;
