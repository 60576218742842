import React from "react";

import { HeaderButton } from "./HeaderButton";

export const LogoutButton = React.memo(
  (): JSX.Element => (
    <HeaderButton
      tooltip="Çıkış"
      type="danger"
      icon="poweroff"
      to={{
        pathname: "/login",
        state: { logout: true },
      }}
    />
  ),
);
