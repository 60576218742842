import React from "react";
import css from "@emotion/css";

export const Logo = React.memo((props: any) => <a css={logoCss} {...props} alt="logo" />);

const logoCss = css`
  font-size: 60px;
  user-select: none;
  vertical-align: middle;
  font-weight: 900;
  letter-spacing: 5px;
  color: #ab1702;
  transition: all 0.3s ease-in-out;
  text-shadow: grey 0px 0px 2px;
  &:hover {
    color: #ab1702;
    text-shadow: #080808 1px 1px 4px;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
  text-decoration: none;
`;
